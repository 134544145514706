.slider-container {
  width: 100%;
  max-width: 500px;
  min-height: 51px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 8px;
  font-size: 18px;
  line-height: 22px;
  box-shadow: 0 0 12px #5b5289;
  margin: 35px 20px 35px auto;
  border-radius: 8px;
}

.slider-container > button {
  border: none;
  background-color: transparent;
  color: lightgray;
  height: 42px;
  width: 45%;
}

.slider-container > .active-btn {
  background-color: rgba(255, 255, 255, 0.925);
  border: none;
  font-weight: 500;
  color: #3b3363;
  border-radius: 8px;
}

/* .slider-btn {
    background-color: rgba(255, 255, 255, 0.925);
    border: none;
    font-weight: 500;
    color: #3b3363;
  }
  
   Wrapper to the whole container */
/*.slider-btn::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 500;
    background-color: transparent;
    border: 1px solid #5b5289;
    border-radius: 8px;
  }
  
  .slider-btn,
  .slider-btn-span {
    min-width: 48%;
    min-height: 42px;
  }
  
  .slider-btn-span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: lightgray;
  }
  
  .slider-container,
  .slider-btn {
    border-radius: 8px;
  }
  
  @media screen {
  } */
