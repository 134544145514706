.container-div {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.inp-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

label {
  color: white;
  font-size: 1rem;
  line-height: 1.25rem;
}

.input-fields {
  background-color: transparent;
  border: 1px solid #5b5289;
  border-radius: 4px;
  width: 100%;
  padding: 4px;
  color: white;
}

.title {
  height: 50px;
}

.description {
  height: 180px;
}

.image-container {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  justify-content: space-between;
  padding: 8px 4px;
  height: 100px;
}

.img-inp {
  min-width: 20%;
  height: 3rem;
  border-radius: 0.5rem;
  background-color: white;
  color: black;
  position: relative;
  margin-bottom: 5%;
  color: transparent;
}

.img-inp::after {
  content: 'Upload Image';
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.5rem 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid;
  color: black;
  background-color: white;
  border-radius: 0.5rem;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  cursor: pointer;
}

.upload-img {
  border-bottom: 0.5px solid black;
  min-width: 100px;
  max-height: 100px;
  aspect-ratio: 1 / 1;
  display: block;
}

.btn-container {
  display: flex;
  justify-content: space-between;
  padding: 16px 4px;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 25px;
}

.cancel-btn,
.add-btn {
  width: 40%;
}
