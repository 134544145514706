.pagination-container {
  width: 100%;
  max-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
  position: relative;
}

.pagination-btn-container {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
}

.pagination-select-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  position: relative;
}
.pagination-select {
  appearance: none;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #5b5289;
  text-align: center;
  color: gray;
  font-size: 1rem;
  cursor: pointer;
}

.paginatiion-label {
  color: lightgray;
  font-size: 0.9rem;
}

.pagination-btn {
  font-size: 1.5rem;
  width: 40px;
  aspect-ratio: 1 / 1;
  background-color: transparent;
  border: none;
  color: white;
  border-bottom: 1px solid #5b5289;
}

.pagination-page-info {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 50px;
}
